import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import { Content } from "rbx";
import PostSummary from "../../components/PostSummary";

const TerminePage = props => {
  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <section className="section">
        <Helmet title={`Neuigkeiten | TV GW`} />
        <div className="container content">
          <Content>
            <h3 className="has-text-weight-bold is-size-4">
              Alle Neuigkeiten & Infos
            </h3>
          </Content>
          {posts.map(({ node: post }) => (
            <PostSummary post={post} />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default TerminePage;

export const terminePageQuery = graphql`
  query TermineQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            termin
            templateKey
            date(formatString: "DD.MM.YYYY")
          }
        }
      }
    }
  }
`;
